import React from 'react';
import './footer.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
  return (
    <div className="footer">
      <p>&copy; 2016 - 2018</p>
      <p>Built with <a href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer" className="footer__link">GatsbyJS</a></p>
      <p>Made on an <a href="https://www.apple.com/macbook-pro/" target="_blank" rel="noopener noreferrer" className="footer__link">Apple Computer</a></p>
      <p>Hosted by <a href="https://firebase.google.com/" target="_blank" rel="noopener noreferrer" className="footer__link">Firebase</a></p>
      <div className="footer__border" />
      <div className="footer__icon-container">
        <a href="https://www.instagram.com/_robertlester/?hl=en" target="_blank" rel="noopener noreferrer" className="footer__link-bar-link">
          <FontAwesomeIcon icon={['fab', 'instagram']} size="2x" className="footer__icon" />
        </a>
        <a href="https://twitter.com/robertlester__?lang=en" target="_blank" rel="noopener noreferrer" className="footer__link-bar-link">
          <FontAwesomeIcon icon={['fab', 'twitter']} size="2x" className="footer__icon" />
        </a>
        <a href="https://www.linkedin.com/in/robert-lester-a0258784/" target="_blank" rel="noopener noreferrer" className="footer__link-bar-link">
          <FontAwesomeIcon icon={['fab', 'linkedin-in']} size="2x" className="footer__icon" />
        </a>
        <a href="https://github.com/roblester09" target="_blank" rel="noopener noreferrer" className="footer__link-bar-link">
          <FontAwesomeIcon icon={['fab', 'github']} size="2x" className="footer__icon" />
        </a>
        <a href="https://dribbble.com/RobSLester" target="_blank" rel="noopener noreferrer" className="footer__link-bar-link">
          <FontAwesomeIcon icon={['fab', 'dribbble']} size="2x" className="footer__icon" />
        </a>
      </div>
  </div>
  )
}

export default Footer;