import React from 'react';
import Link from 'gatsby-link';
import { window } from 'browser-monads';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons'

import './mobileNav.scss';

library.add(fab, fas, far)

const MobileNav = () => (
  <div>
    <div className="mobileNav">
      <ul className="__list">
        <li className="__item">
          <Link to="/" className={window.location.href.indexOf('') > 0 ? '__link __active' : '__link'}>
            <FontAwesomeIcon icon={['fas', 'home']} size="2x" />
            <p>Home</p>
          </Link>
        </li>
        <li className="__item">
          <Link to="/design" className={window.location.href.indexOf('design') > 0 ? '__link __active' : '__link'}>
            <FontAwesomeIcon icon={['fas', 'desktop']} size="2x" />
            <p>Design</p>
          </Link>
        </li>
        <li className="__item">
          <Link to="/code" className={window.location.href.indexOf('code') > 0 ? '__link __active' : '__link'}>
            <FontAwesomeIcon icon={['fas', 'code']} size="2x" />
            <p>Code</p>
          </Link>
        </li>
        <li className="__item">
          <Link to="/about" className={window.location.href.indexOf('about') > 0 ? '__link __active' : '__link'}>
            <FontAwesomeIcon icon={['far', 'meh']} size="2x" />
            <p>About</p>
          </Link>
        </li>
        <li className="__item">
          <Link to="/contact" className={window.location.href.indexOf('contact') > 0 ? '__link __active' : '__link'}>
            <FontAwesomeIcon icon={['far', 'envelope']} size="2x" />
            <p>Contact</p>
          </Link>
        </li>
      </ul>
    </div>
  </div>
)

export default MobileNav